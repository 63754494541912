<template>
  <ww-open-data :type="type" :openid="openid" />
</template>
<script>
  export default {
    props: ['type', 'openid'],
    mounted() {
      WWOpenData.bind(this.$el)

    },
    methods:{
    }
  }
</script>
