/**
 * Created by fworld on 2019/9/25.
 */
import request from '@/util/request'


export const workUserDeviceBind = (data) => {
  return request({
    'url': '/api/v1/work/user/device/bind',
    'method': 'POST',
    data
  })
}



export const workUserDeviceUnbind = (params) => {
  return request({
    'url': '/api/v1/work/user/device/unbind',
    'method': 'GET',
    params
  })
}

export const workUserDeviceUnion = (params) => {
  return request({
    'url': '/api/v1/work/user/device/union',
    'method': 'GET',
    params
  })
}

export const workUserDeviceList = (params) => {
  return request({
    'url': '/api/v1/work/user/device/list',
    'method': 'GET',
    params
  })
}


export const workUsertDeviceViews = (params) => {
  return request({
    'url': '/api/v1/work/user/device/views',
    'method': 'GET',
    params
  })
}

export const workUserDeviceViewCnt = (params) => {
  return request({
    'url': '/api/v1/work/user/device/viewCnt',
    'method': 'GET',
    params
  })
}




