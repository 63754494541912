<template>
  <div>
    <p style="font-size: 12px; color: #999;">点击部门名称展开下级部门信息</p>
    <el-tree :data="groups" @node-click="nodeClick" node-key="uuid" :default-expand-all="true"
      :expand-on-click-node="false">
      <div class="custom-tree-node" slot-scope="{ node, data }">
        <div class="department" :class="{ active: data.id === activeId }" v-if="data.name != 'more'">
          <span :class="{ circle: data.realName == '' }"></span>
          <p class="department-name">
            <department-name :openid="data.id" type="departmentName"></department-name>
            <template v-if="mode === 1">
              <span v-if="isSelect(data.id)">(已选)</span>
            </template>
          </p>
          <el-button class="department-button" type="text" size="mini" v-if="mode === 0" @click.stop="nodeClick(data)">
            查看
          </el-button>

          <p v-else>
            <el-button class="department-button" type="text" size="mini" @click.stop="selectNode(data, 0)">
              <template v-if="isSelect(data.id)">取消选择</template>
              <template v-else>选取本级部门</template>
            </el-button>
            <el-button class="department-button" type="text" size="mini" @click.stop="selectNode(data, 1)">
              <template v-if="searchNode(data.id).id >= 0 && searchNode(data.id).type === 1">取消绑定</template>
              <template v-else>选取本级及下级部门</template>

            </el-button>
          </p>
        </div>
        <el-button v-else type="text" @click.stop="loadMore">加载更多</el-button>
      </div>
    </el-tree>
  </div>

</template>

<script>
import { workDepartmentCnt, workDepartmentTree } from '@/api/work/department';

import DepartmentName from '@/components/DepartmentName';
import { mapGetters } from "vuex";

export default {
  name: '',
  components: {
    DepartmentName
  },
  props: {
    mode: {
      type: Number,
      default: 0,
    }
  },
  mounted: function () {
    this.loadDepartment()
  },
  computed: {
    ...mapGetters(['employee']),
  },
  data: function () {
    return {
      start: 0,
      limit: 20,
      total: 0,
      groups: [],
      data: [],
      resolve: null,
      select: [],
      kv: {},
      activeId: 1
    }
  },
  methods: {
    loadMore() {
      this.start += this.limit
      console.log("enter load more")
      this.loadDept()
    },

    loadDepartment() {
      workDepartmentTree({ corpid: this.employee.corpid, code: this.employee.code }).then(res => {
        let groups = res.data || []
        // this.groups = [{uuid:``, label:"全部分组"}].concat(groups)
        this.groups = groups
        for (let i = 0; i < groups.length; i++) {
          this.kv[groups[i].uuid] = groups[i]
        }
        if (groups.length > 0) {
          this.activeUuid = groups[0].uuid
          this.$emit(`node-click`, groups[0])
        }
      })
    },
    selectNode(data, type) {
      let idx = this.select.findIndex(item => item.id === data.id)
      if (idx >= 0) {
        let node = this.select[idx]
        if (node.type !== type && type === 1) {
          node.type = 1
        } else {
          this.select.splice(idx, 1)
          this.select = this.select || []
        }

      } else {
        this.select.push({ ...data, type: type })
      }
    },
    searchNode(id) {
      let v = {
        id: -1,
        type: 0
      }
      let idx = this.select.findIndex(item => item.id === id)
      if (idx >= 0) {
        v.id = 0
        v.type = this.select[idx].type
      }
      return v
    },
    isSelect(id) {
      return this.select.findIndex(item => item.id === id) >= 0
    },

    loadDept() {
      workDepartmentTree({
        corpid: this.employee.corpid,
        code: this.employee.code,
        wxDeptId: this.activeId,
        start: this.start,
        limit: this.limit,
      }).then(res => {


        let resData = res.data || []
        if (res.data.length == this.limit) {
          resData.push({ uuid: `more`, name: `more`, label: "加载更多" })
        }

        if (this.data.children && this.data.children.length > 0) {
          if (this.data.children[this.data.children.length - 1].uuid === `more`) {
            this.data.children.splice(this.data.children.length - 1, 1)
          }
        }

        this.data.children = this.data.children || []
        this.data.children = this.data.children.concat(resData)
      })
    },
    nodeClick(data) {
      this.activeId = data.id
      this.start = 0
      this.data = data

      workDepartmentCnt({
        corpid: this.employee.corpid,
        code: this.employee.code,
        wxDeptId: this.activeId,
      }).then(res => {
        this.total = parseInt(res.data)
      })

      this.loadDept()
      this.$emit(`node-click`, data)
    },
    getSelect() {
      return this.select
    },
    getGroups() {
      return this.groups
    }
  },

}
</script>

<style lang="scss" scoped>
.custom-tree-node {
  width: 90%;

  .department {
    width: 100%;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
  }

  .circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: $_theme_color;
    border-radius: 50%;
    margin-right: 6px;
  }

  .department-name {
    display: inline-block;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    position: relative;
  }

  .department-button {
    margin: 0 4px;
  }

  .active {
    * {
      color: $_theme_color;
    }
  }

}

.el-tree-node__content {
  height: 0;
}


.group-operate {
  .el-button {
    display: block;
    margin-left: 10px;
  }
}
</style>
