/**
 * Created by fworld on 2019/9/25.
 */
import request from '@/util/request'

export const workDepartmentSync = (params) => {
  return request({
    'url': '/api/v1/work/department/sync',
    'method': 'GET',
    params
  })
}

export const departmentRename = (data) => {
  return request({
    'url': '/api/v1/work/department/rename',
    'method': 'POST',
    data
  })
}

export const workDepartmentEmployeeUnion = (params) => {
  return request({
    'url': '/api/v1/work/department/employee/union',
    'method': 'GET',
    params
  })
}

export const workDepartmentEmployeeList = (params) => {
  return request({
    'url': '/api/v1/work/department/employee/list',
    'method': 'GET',
    params
  })
}


export const workDepartmentDevices = (params) => {
  return request({
    'url': '/api/v1/work/department/devices',
    'method': 'GET',
    params
  })
}

export const workDepartmentDeviceBind = (data) => {
  return request({
    'url': '/api/v1/work/department/device/bind',
    'method': 'POST',
    data
  })
}

export const workDepartmentDeviceUnbind = (params) => {
  return request({
    'url': '/api/v1/work/department/device/unbind',
    'method': 'GET',
    params
  })
}


export const workDepartmentDeviceViews = (params) => {
  return request({
    'url': '/api/v1/work/department/device/views',
    'method': 'GET',
    params
  })
}

export const workDepartmentDeviceViewCnt = (params) => {
  return request({
    'url': '/api/v1/work/department/device/viewCnt',
    'method': 'GET',
    params
  })
}



export const workDepartmentList = (params) => {
  return request({
    'url': '/api/v1/work/department/list',
    'method': 'GET',
    params
  })
}

export const workDepartmentCnt = (params) => {
  return request({
    'url': '/api/v1/work/department/cnt',
    'method': 'GET',
    params
  })
}

export const workDepartmentTree = (params) => {
  return request({
    'url': '/api/v1/work/department/tree',
    'method': 'GET',
    params
  })
}

export const workDepartmentBindDevice = (data) => {
  return request({
    'url': '/api/v1/work/department/bind/device',
    'method': 'POST',
    data
  })
}

export const workDepartmentDeviceBatchBind = (data) => {
  return request({
    'url': '/api/v1/work/department/device/batch/bind',
    'method': 'POST',
    data
  })
}









